import { Link } from 'react-router-dom';
import "./privacy-policy.css";
import logo from '../images/Logo.png';

const PrivacyPolicy = () => {
    return (
        <div className='text'>
            <div className='w-full text-center'>
                <Link to="/">
                    <img
                        className='inline h-8 sm:h-12 mb-1'
                        src={logo}
                        alt='ruby dev logo'
                    />
                </Link>
            </div>
<h2 className='text-3xl my-5 pb-1 font-bold text-center'>RUBY Dev. Privacy Policy</h2>
<p>Ruby Dev. (&ldquo;Service&rdquo;) takes the privacy of all its users and clients very seriously and is committed to protecting all personal information with the highest level of security. The Service is also committed to transparency in all matters related to anyone&rsquo;s information. If you have questions or concerns beyond the following Privacy Policy, please contact us at <u><a href="mailto:info@rubydev.ca">info@rubydev.ca</a></u>.</p>
<br></br>
<p>The following Privacy Policy and the practices of the Service are in compliance with the Personal Information Protection and Electronic Documents Act (PIPEDA).</p>
<hr className='my-5 border-black'></hr>
<ol>
<li>
<p className='font-bold'>Accountability</p>
<ol>
<li>
<p>The Service assumes responsibility for the personal information of all its users and clientele (&ldquo;personal information&rdquo;).</p>
</li>
<li>
<p>The Service appoints personnel that work to ensure that all personal information handled by the service is protected.</p>
</li>
<li>
<p>The Services commits to train all staff employed by the Service to follow the best practices in ensuring that all personal information handled by the service is protected.</p>
</li>
</ol>
</li>
<li>
<p className='font-bold'>Purpose</p>
<ol>
<li>
<p>The Service collects personal information to enable the use of its platform.</p>
</li>
<li>
<p>The personal information is used to identify users, track data or to allow special permissions</p>
</li>
<li>
<p>The information collected can include the following: &ldquo;Full Names&rdquo;, &ldquo;Email Addresses&rdquo;, &ldquo;Phone Numbers&rdquo;, &ldquo;UserIds&rdquo; and &ldquo;Usernames&rdquo;.</p>
<ol>
<li>
<p>The Service may also collect additional information if requested by the client.</p>
</li>
<li>
<p>The additional information must be clearly listed in the contract and between and agreed upon by the Service and the client.</p>
</li>
</ol>
</li>
</ol>
</li>
<li>
<p className='font-bold'>Consent</p>
<ol>
<li>
<p>The Service will require written consent for the disclosure and distribution of any personal information from the party to which the information belongs.</p>
</li>
<li>
<p>The Service, under no circumstance, will disclose or distribute personal information without explicit written consent.</p>
</li>
<li>
<p>When obtaining consent to disclose or distribute personal information, the Service will, in detail, explain the purpose of the action before receiving permission.</p>
</li>
</ol>
</li>
<li>
<p className='font-bold'>Collection</p>
<ol>
<li>
<p>The collection of all personal information by the Service is strictly limited to the that which is needed for the purposes identified by the Service and its clients and users.</p>
</li>
<li>
<p>The Service will not collect any personal information that is not agreed upon by the Service and its client and users.</p>
</li>
</ol>
</li>
<li>
<p className='font-bold'>Limiting Use, Disclosure, and Retention</p>
<ol>
<li>
<p>The Service is committed to only use the personal information of its users and clients as is required by the client and the platform built by the Service for the client.</p>
</li>
<li>
<p>The Service will use the personal information to create &ldquo;User Profiles&rdquo;, &ldquo;User Accounts&rdquo; and to identify users with different permissions.</p>
</li>
<li>
<p>The Service will use the personal information to collect data that will help the Service improve the quality of its platform.</p>
</li>
<li>
<p>The Service will use the personal information to collect data of its users and disclose it back to the users or the clients for improving the clients service or the users experience on the platform.</p>
</li>
<li>
<p>The disclosure of any personal information to 3<sup>rd</sup> parties will only be done after receiving written consent from the holders of the information.</p>
</li>
<li>
<p>The disclosure of any personal information back to the client or the userbase will be done through an agreement between the Service and the client and users.</p>
</li>
<li>
<p>The Service will retain any personal information only while the user is in use of the Service&rsquo;s platform and an extra 6 months after the user decides to leave the Service&rsquo;s platform.</p>
<ol>
<li>
<p>The Service can retain the information for longer after deletion if it is agreed upon in the contract between the Service and the client that the user comes from.</p>
</li>
</ol>
</li>
<li>
<p>The Service is not responsible for retention of personal information from 3<sup>rd</sup> parties that have access to the personal information, either through the Service or not.</p>
</li>
</ol>
</li>
<li>
<p className='font-bold'>Accuracy</p>
<ol>
<li>
<p>The users and clients reserve the right to update their personal information as often as they please to ensure that information representing them is accurate and complete.</p>
</li>
<li>
<p>The Service will commit to ensuring that it regularly updates its records to reflect the most up-to-date accurate information.</p>
</li>
</ol>
</li>
<li>
<p className='font-bold'>Safeguards</p>
<ol>
<li>
<p>The Service will stay up to date with the most recent security standards for cloud servers.</p>
</li>
<li>
<p>The Service will ensure the use of safe external servers that include data and network encryption.</p>
</li>
<li>
<p>The Service will limit access to personal information on a need-to-know basis.</p>
</li>
<li>
<p>The Service will not be held liable for malicious attacks on the Services platform or mishaps made by external servers and 3<sup>rd</sup> parties.</p>
</li>
</ol>
</li>
<li>
<p className='font-bold'>Openness</p>
<ol>
<li>
<p>The Service will make this privacy policy accessible to the public and especially to the clients and users.</p>
</li>
<li>
<p>The Service will inform the client and users of any updates to the privacy policy.</p>
</li>
</ol>
</li>
<li>
<p className='font-bold'>Individual Access</p>
<ol>
<li>
<p>Any user of the Service&rsquo;s platform or a client may request access to the personal information the Service holds on them, and the Service will provide the personal information in its entirety.</p>
</li>
<li>
<p>A client may request access to the personal information the Service holds on the users of said client, and the Service will provide the personal information in its entirety.</p>
</li>
<li>
<p>Any user or client can request to remove their personal information from being accessed by the Service.</p>
<ol>
<li>
<p>The Service will not be responsible for removing personal information from 3<sup>rd</sup> parties that have received the information through the Service or not.</p>
</li>
</ol>
</li>
</ol>
</li>
<li>
<p className='font-bold'>Challenging Compliance</p>
<ol>
<li>
<p>Any user or client is able to challenge the above principles by contacting the Services&rsquo; Chief Privacy Officer at <u><a href="mailto:info@rubydev.ca">info@rubydev.ca</a></u>.</p>
</li>
</ol>
</li>
</ol>
        </div>
    );
};

export default PrivacyPolicy;