import { Routes, Route } from 'react-router-dom';

import './App.css';
//import Header from './components/Header';
//import Main from './components/Main';
import PrivacyPolicy from './components/privacy-policy'
import Temp from './components/Temp';

function App() {
  return (
    <div className='App'>
      
      
      <Routes>
        <Route path="/" element={<Temp />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>

    </div>
  );
}

export default App;
