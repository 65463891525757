import logo from '../images/Logo.png';

const Temp = () => {
  return (
    <div
      className='flex items-center justify-center min-h-screen bg-slate-200 '
      style={{ fontFamily: "'Quicksand', sans-serif" }}
    >
      <div className='text-center'>
        <img
          className='h-8 w-auto sm:h-12 m-auto mb-1'
          src={logo}
          alt='ruby dev logo'
        />
        <h1 className='text-3xl pb-1 font-bold'>Ruby Dev.</h1>
        <p className='pb-10'>Fast & Affordable Software Development</p>
      </div>
      <div className='absolute bottom-0 pb-5 text-center'>
        <p className='text-xl font-medium tracking-widest'>COMING SOON</p>
      </div>
    </div>
  );
};

export default Temp;
